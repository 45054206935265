@font-face {
  font-family: Futura New;
  src: url("FuturaNewLight-Reg.c3e4e517.eot");
  src: local(Futura New Light Reg), local(FuturaNewLight-Reg), url("FuturaNewLight-Reg.c3e4e517.eot#iefix") format("embedded-opentype"), url("FuturaNewLight-Reg.9d1d45c1.woff2") format("woff2"), url("FuturaNewLight-Reg.edbe4070.woff") format("woff"), url("FuturaNewLight-Reg.9526e345.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Futura New;
  src: url("FuturaNewBold-Reg.804ccc84.eot");
  src: local(Futura New Bold Reg), local(FuturaNewBold-Reg), url("FuturaNewBold-Reg.804ccc84.eot#iefix") format("embedded-opentype"), url("FuturaNewBold-Reg.7b608f5d.woff2") format("woff2"), url("FuturaNewBold-Reg.cd994ed8.woff") format("woff"), url("FuturaNewBold-Reg.ce513915.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Futura New;
  src: url("FuturaNewBook-Reg.08ed4795.eot");
  src: local(Futura New Book Reg), local(FuturaNewBook-Reg), url("FuturaNewBook-Reg.08ed4795.eot#iefix") format("embedded-opentype"), url("FuturaNewBook-Reg.24b9e46b.woff2") format("woff2"), url("FuturaNewBook-Reg.b7171b3b.woff") format("woff"), url("FuturaNewBook-Reg.f5a339aa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html, body {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  margin: 0;
  font-family: Futura New, Arial, sans-serif;
}

button:focus, input:focus {
  outline: none;
}

p, h1, h2, h3, ol, ul {
  margin: 0;
}

.overflow-hidden, .body-noscroll {
  overflow: hidden;
}

.overlay {
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay_active {
  opacity: 1;
  visibility: visible;
}

.nowrap {
  white-space: nowrap;
}

.owl-carousel:not(.owl-loaded) {
  opacity: 0;
  visibility: hidden;
  display: block;
}

.main__container {
  box-sizing: border-box;
  min-width: 320px;
  margin-top: 111px;
  padding: 0 16px;
}

@media (width >= 768px) {
  .main__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

@media (width >= 1200px) {
  .main__container {
    width: 1144px;
  }
}

@media (width >= 1920px) {
  .main__container {
    width: 1530px;
  }
}

.main__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.main__logo {
  width: 125px;
  height: 108px;
  margin-bottom: 13px;
}

.main__logo img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.main__logo-text {
  text-align: center;
  width: 134px;
  height: 25px;
  margin-bottom: 6px;
}

.main__logo-text img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.main__button {
  color: #fff;
  background: #315de9;
  border: 0;
  border-radius: 8px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  font-weight: 500;
  line-height: 41px;
  box-shadow: -1px 3px 10px #0000000f;
}

.page {
  background: #f5f9fe;
  flex-direction: column;
  min-width: 320px;
  min-height: 100%;
  display: flex;
  overflow-x: hidden;
}

.page__container {
  flex: 1 0 auto;
}

.page__content {
  height: 100%;
}

.page__footer {
  flex: none;
}

.input {
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
  display: flex;
  position: relative;
}

.input__input {
  resize: none;
  color: #000;
  appearance: none;
  box-sizing: border-box;
  background: #f5f9fe;
  border: 1px solid #315de9;
  border-radius: 8px;
  outline: none;
  height: 36px;
  padding: 0 8px;
  font-size: 16px;
  line-height: 36px;
}

.input__input::-moz-placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input:-ms-input-placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input::placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input::-ms-clear {
  display: none;
}

.input__input_error {
  border-color: red;
}

.input_error-text {
  display: none;
}

.input_error .input__input {
  border-color: red;
}

.input_error .input__label {
  display: flex;
}

.input_error .input_error-text {
  color: #eb5757;
  letter-spacing: .05em;
  text-transform: none;
  white-space: nowrap;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: block;
  position: absolute;
  bottom: -16px;
}

.choice__info {
  z-index: 2000;
  position: fixed;
  inset: auto 8px 8px;
}

.choice__items {
  background: #f5f9fe;
  border-radius: 16px;
  max-height: 71vh;
  padding: 8px 12px;
  overflow: auto;
}

.choice__item {
  color: #000;
  background: #f5f9fe;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
}

.choice__item:first-child {
  border-radius: 16px 16px 0 0;
}

.choice__item:last-child {
  border-radius: 0 0 16px 16px;
  margin-bottom: 0;
}

.choice__item + .choice__item {
  padding-top: 4px;
  position: relative;
}

.choice__item + .choice__item:before {
  content: "";
  background: radial-gradient(98.81% 11088500% at 0 99.8%, #28afb066 0%, #28afb000 100%);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.choice__item svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.choice__button {
  text-align: center;
  color: #28afb0;
  background: #f5f9fe;
  border: 0;
  border-radius: 16px;
  width: 100%;
  height: 57px;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 57px;
}

.market__header {
  -webkit-box-shadow: 0 5px 10px 2px #aedde833;
  -moz-box-shadow: 0 5px 10px 2px #aedbe833;
  background: #f5f9fe;
  border-bottom: 1px solid #4b6af5;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  box-shadow: 0 5px 10px 2px #aeb6e846;
}

.market__title {
  color: #1f271b;
  justify-content: space-between;
  align-items: center;
  margin: 15px 5px 5px;
  font-size: 20px;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.user__profile svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
  margin-right: 9px;
}

.market__corners_list-icon svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
  margin-left: 9px;
}

.headerContainer {
  justify-content: center;
  align-items: center;
  max-width: 65%;
  display: flex;
}

.headerContainer span {
  text-align: center;
  max-width: 95%;
}

.headerContainer svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
  margin-top: 5px;
  margin-left: 5px;
}

.market__logo svg {
  stroke: #1f271b;
}

.market__date {
  font-size: 16px;
}

.market__orders_count {
  font-size: 18px;
}

.market__items {
  max-width: 359px;
  margin: 0 auto;
  padding-top: 114px;
}

.market__item {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  justify-content: space-between;
  padding: 8px;
  display: flex;
}

.market__item + .market__item {
  margin-top: 8px;
}

.market__col_right {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.corner__list {
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 4px;
  display: flex;
}

.order__status-time {
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

.order__status-small {
  padding-right: 50px;
  font-size: 12px;
  line-height: 1;
}

.order__status-waiting {
  color: #ffc700;
}

.order__status-confirmed {
  color: #60d6a9;
}

.order__status-refused {
  color: #c7c7d4;
}

.order__status-cooking {
  color: #ffa640;
}

.order__status-ready {
  color: #81fec6;
}

.order__status-waiting_on_counter, .order__status-issued_courier {
  color: #717179;
}

.order__status-received {
  color: #00bc29;
}

.order__time {
  color: #000;
  font-size: 12px;
  font-weight: 200;
  line-height: 1;
}

.market__row {
  align-items: center;
  display: flex;
}

.market__row + .market__row {
  margin-top: 4px;
}

.market__number {
  color: #000;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.discount_number {
  color: #28afb0;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.market__tel {
  color: #19647e;
  opacity: .5;
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 14px;
}

.market__tel a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.market__sum {
  color: #28afb0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.market__name {
  color: #19647e;
  margin-right: 8px;
  font-size: 14px;
  line-height: 14px;
}

.order__eat_place {
  color: #19647e;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;
}

.market__status {
  color: #fff;
  white-space: nowrap;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 10px;
  line-height: 1;
}

.market__status_waiting {
  background: #ffc700;
}

.market__status_confirmed {
  background: #60d6a9;
}

.market__status_refused {
  background: #c7c7d4;
}

.market__status_expects {
  background: #ffc700;
}

.market__status_cooking {
  background: #ffa640;
}

.market__status_ready {
  color: #19647e;
  background: #81fec6;
}

.market__status_received {
  background: #00bc29;
}

.market__status_issued_courier, .market__status_waiting_on_counter {
  background: #555a57;
}

.order {
  max-width: 376px;
  margin: 10px auto;
  padding-bottom: 72px;
}

.order__name {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.block {
  color: #fff;
  display: block;
}

.order__name svg {
  width: 22px;
  height: 22px;
  display: inline-block;
}

.order__status {
  white-space: nowrap;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 16px;
  line-height: 1;
}

.order__status_waiting {
  color: #19647e;
  background: #ffc700;
}

.order__status_confirmed {
  color: #fff;
  background: #60d6a9;
}

.order__status_refused {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_cooking {
  color: #19647e;
  background: #ffa640;
}

.order__status_ready {
  color: #19647e;
  background: #81fec6;
}

.order__status_readybtn {
  color: #fff;
  background: #00bc29;
  box-shadow: 2.3px 4.6px 4.6px #0000006e;
}

.order__status_issued {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_received {
  color: #00bc29;
  background: #fff;
}

.order__title {
  color: #1f271b;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 40px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.order__title svg {
  fill: #1f271b;
  stroke: none;
  stroke-width: 2px;
  margin-left: 8px;
}

.order__logo svg {
  stroke: #1f271b;
  fill: none;
}

.order__table {
  max-width: 343px;
  margin: 46px auto 8px;
}

.order__row {
  justify-content: space-between;
  line-height: 18px;
  display: flex;
}

.order__row + .order__row {
  margin-top: 9px;
}

.order__col-name {
  color: #19647e;
  font-size: 14px;
  font-weight: 600;
}

.order__col-value {
  color: #000;
  text-align: right;
  font-size: 16px;
}

.order__client {
  color: #19647e;
  border-bottom: 1px solid;
}

.order__client a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.order__total {
  color: #000;
  border-top: 1px dashed #0003;
  flex-direction: column;
  justify-content: space-between;
  max-width: 343px;
  margin: 8px auto 25px;
  padding-top: 10px;
  display: flex;
}

.order__subtotal1 {
  justify-content: space-between;
  font-weight: 600;
  display: flex;
}

.order__total-text {
  text-transform: uppercase;
  font-size: 14px;
}

.order__total-sum {
  font-size: 16px;
}

.order__item {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
}

.order__item + .order__item {
  margin-top: 8px;
}

.order__item_active .order__body {
  display: block;
}

.order__header {
  align-items: center;
  display: flex;
}

.order__header-img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.order__header-img img {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.order__header-name {
  color: #19647e;
  font-size: 16px;
  line-height: 20px;
}

.order__header-btn {
  border: none;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 18px;
  margin-left: auto;
  padding-bottom: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.order__body {
  margin-top: 8px;
  display: none;
}

.order__body-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.order__num {
  color: #000;
  width: 10px;
  min-width: 10px;
  margin-bottom: 2px;
  margin-right: 12px;
  font-size: 16px;
}

.order__dish {
  color: #000;
  width: 170px;
  margin-bottom: 2px;
  margin-right: 29px;
  font-size: 16px;
  font-weight: 500;
}

.order__dish-extra {
  width: 170px;
  margin-bottom: 8px;
  margin-right: 29px;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
}

.order__number {
  text-align: right;
  margin-bottom: 2px;
  font-size: 14px;
}

.order__number-extra {
  text-align: right;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
}

.order__number_bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.order__sum {
  white-space: nowrap;
  margin-bottom: 2px;
  font-size: 14px;
}

.order__sum-extra {
  white-space: nowrap;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
}

.order__pricesum {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  display: flex;
}

.order__sum_md {
  font-size: 16px;
  line-height: 18px;
}

.order__itemprice {
  color: #717179;
  margin-right: 4px;
  font-size: 12px;
}

.order__body-item + .order__body-item, .order__footer {
  border-top: 1px dashed #0003;
  margin-top: 8px;
  padding-top: 8px;
}

.order__button-wrap {
  background: #f5f9fe;
  padding: 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 20px #3143522e;
}

.order__button {
  color: #19647e66;
  background: #1f1d1b1a;
  border: 0;
  border-radius: 8px;
  justify-content: center;
  width: 100%;
  max-width: 359px;
  height: 56px;
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 56px;
  display: flex;
  box-shadow: -1px 3px 10px #0000000f;
}

.order__button svg {
  display: none;
}

.order__button_accept {
  color: #fff;
  background: #00bc29;
  align-items: center;
}

.order__button_accept svg {
  fill: #fff;
  margin-right: 12px;
  display: block;
}

.order__button_primary {
  color: #fff;
  background: #28afb0;
}

.popup-page__content {
  z-index: 2000;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-page__body {
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  width: 220px;
  padding: 24px 43px;
}

.popup-page__name {
  white-space: nowrap;
  color: #000;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 34px;
}

.popup-page__tel {
  white-space: nowrap;
  color: #000;
  font-size: 16px;
  line-height: 18px;
  text-decoration: none;
}

.button__div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.popup-page__logout {
  color: #000;
  background: #fff;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 6px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.popup-page__logout svg {
  stroke: #000;
  fill: none;
  stroke-width: 2px;
  margin-right: 8px;
}

.loader {
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.fond {
  margin: auto;
}

.container_general {
  -ms-animation: animball_two 1s infinite;
  width: 44px;
  height: 44px;
  animation: 1s infinite animball_two;
}

.container_mixt {
  width: 44px;
  height: 44px;
  position: absolute;
}

.ballcolor {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.ball_1, .ball_2, .ball_3, .ball_4 {
  -ms-animation: animball_one 1s infinite ease;
  animation: 1s infinite animball_one;
  position: absolute;
}

.ball_1 {
  background-color: #cb2025;
  top: 0;
  left: 0;
}

.ball_2 {
  background-color: #f8b334;
  top: 0;
  left: 24px;
}

.ball_3 {
  background-color: #00a096;
  top: 24px;
  left: 0;
}

.ball_4 {
  background-color: #97bf0d;
  top: 24px;
  left: 24px;
}

@keyframes animball_one {
  0% {
    position: absolute;
  }

  50% {
    opacity: .5;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  100% {
    position: absolute;
  }
}

@-webkit-keyframes animball_two {
  0% {
    -webkit-transform: rotate(0)scale(1);
  }

  50% {
    -webkit-transform: rotate(360deg)scale(1.3);
  }

  100% {
    -webkit-transform: rotate(720deg)scale(1);
  }
}

@-moz-keyframes animball_two {
  0% {
    -moz-transform: rotate(0)scale(1);
  }

  50% {
    -moz-transform: rotate(360deg)scale(1.3);
  }

  100% {
    -moz-transform: rotate(720deg)scale(1);
  }
}

@keyframes animball_two {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(360deg)scale(1.3);
  }

  100% {
    transform: rotate(720deg)scale(1);
  }
}

.outletlist__item {
  color: #000;
  background: #f5f9fe;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
}

.outletlist__item + .outletlist__item {
  margin-bottom: 4px;
  position: relative;
}

.outletlist__item + .outletlist__item:before {
  content: "";
  background: radial-gradient(98.81% 11088500% at 0 99.8%, #28afb066 0%, #28afb000 100%);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.outletlist__item svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
  margin-left: 9px;
}

.outlet_row_left {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 70%;
  margin-top: 5px;
  display: flex;
}

.outlet_row_right {
  flex-direction: row;
  flex: 30%;
  justify-content: flex-end;
  align-items: center;
  max-width: 42%;
  display: flex;
}

.outletlist__image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.outletlist__image img {
  width: 40pxx;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  display: block;
}

.outletlist__title {
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 60%;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  overflow: hidden;
}

.outlet_work_hours {
  color: #315de9;
}

.outlet_work_hours_off {
  color: red;
}

.market__status_string {
  color: #315de9;
  text-align: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 12px 10px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
}

.outlet__nan {
  text-align: center;
  color: red;
}

.outlet__on {
  text-align: center;
  color: #315de9;
}

.outlets__title {
  color: #fff;
  text-align: center;
  margin: auto;
  font-size: 30px;
  font-weight: bold;
}

.order__extra-pricesum {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  display: flex;
}

.wave-wrapper {
  justify-content: center;
  height: 30rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.wave {
  background-color: #4b6af5;
  border-radius: 35%;
  width: 48rem;
  height: 48rem;
  animation-name: waves;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: 10rem;
}

.wave + .one {
  opacity: .4;
  animation-duration: 7s;
}

.wave + .two {
  background-color: #315de9;
  animation-duration: 9s;
}

.wave + .three {
  background-color: #2c4f86;
  animation-duration: 12s;
}

@keyframes waves {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.markets__row {
  background: rgba(155, 194, 240, #3143522e);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 8px;
  display: flex;
}

.markets__left, .markets__right {
  background-color: #dee5e7;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 40vw;
  padding: 5px;
  display: flex;
}

.order__weight {
  color: #0009;
  font-size: 12px;
}

.order__position {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.yandex_order {
  border: 1px solid gold;
  box-shadow: inset 0 -3em 3em #ffc0000d, 0 0 #fff, .3em .3em 1em #ffc0001a;
}

.ninja_order {
  border: 1px solid #20b2aa;
  box-shadow: inset 0 -3em 3em #3cb30d0d, 0 0 #fff, .3em .3em 1em #ffffff1a;
}

.order__itemnote {
  color: #912707;
  padding-left: 25px;
  font-size: 12px;
  line-height: 18px;
}

.card__weight {
  color: #19647e;
  font-size: 10px;
}

.card__img {
  border-radius: 50%;
  margin: auto;
}

.note__title {
  font-weight: bolder;
}

.distance_marker_danger {
  color: #eb5757;
  font-size: 10px;
}

.distance_marker_norm {
  color: #00a096;
  font-size: 10px;
}
/*# sourceMappingURL=index.1e4f47e7.css.map */
